import 'select2';

const defaultOptions = {
    theme: 'papa'
};

const initSelect = ($elem, customOptions = {}) => {
    const options = $.extend({}, Object.assign(defaultOptions, { dropdownParent: $elem }), customOptions);
    const $select = $elem.find('select');

    if (!$select.length) {
        return;
    }

    $select.select2(options);
};

export default initSelect;
