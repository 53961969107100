const selectors = {
    link: '.js-anchor-link'
};

const DURATION = 500;
const anchorScrolling = () => {
    const $link = $(selectors.link);

    if ($link.length < 1) {
        return;
    }

    $link.each((index, item) => {
        const $item = $(item);

        $item.on('click', function (event) {
            event.preventDefault();
            const $this = $(event.currentTarget);
            const id = $this.attr('href');
            const $target = $(`${id}`);

            $('html, body').animate(
                {
                    scrollTop: $target.offset().top
                },
                DURATION
            );
        });
    });
};

export default anchorScrolling;
