import Inputmask from 'inputmask';

const defaultOptions = {
    mask: '+7 999 999 99 99',
    showMaskOnHover: false,
    clearIncomplete: true,
    placeholder: ''
};

const phoneInputMask = () => {
    const $inputMaskPhone = $('.js-phone-imputmask');

    if ($inputMaskPhone.length < 1) {
        return;
    }

    $inputMaskPhone.each((index, item) => {
        const $item = $(item);
        const im = new Inputmask(defaultOptions);

        im.mask($item);
    });
};

export default phoneInputMask;
