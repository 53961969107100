const selectors = {
    openBtn: '.js-mobile-menu-open-btn',
    closeBtn: '.js-mobile-menu-close-btn',
    overlay: '.js-mobile-menu-overlay',
    menu: '.js-mobile-menu'
};

const state = {
    active: 'is-active',
    ovHidden: 'ov-hidden'
};

const toggleMobileMenu = () => {
    const $body = $('body');
    const $openBtn = $(selectors.openBtn);
    const $closeBtn = $(selectors.closeBtn);
    const $mobileMenu = $(selectors.menu);
    const $overlay = $(selectors.overlay);

    if (!$openBtn.length) {
        return;
    }

    const openMenu = () => {
        $mobileMenu.addClass(state.active);
        $overlay.addClass(state.active);
        $overlay.addClass(state.active);
        $body.addClass(state.ovHidden);
    };

    const closeMenu = () => {
        $mobileMenu.removeClass(state.active);
        $overlay.removeClass(state.active);
        $overlay.removeClass(state.active);
        $body.removeClass(state.ovHidden);
    };

    $openBtn.on('click', openMenu);
    $closeBtn.on('click', closeMenu);
    $overlay.on('click', closeMenu);
};

export default toggleMobileMenu;
