import '@babel/polyfill';
import 'classlist-polyfill';

// Expose global jQuery variable
window.jQuery = window.$ = jQuery;

import '../utils/siteNamespace';
import universalModal from '../modules/universalModal';
import anchorScrolling from '../modules/anchor-scrolling';
import phoneInputMask from '../modules/phone-input-mask';
import select from '../modules/select';
import toggleMobileMenu from '../modules/toggleMobileMenu';

$(() => {
    universalModal();
    anchorScrolling();
    phoneInputMask();
    select();
    toggleMobileMenu();
});
