import initSelect from '../utils/initSelect';

export default function select() {
    const $selects = $('.js-select');
    const options = {
        minimumResultsForSearch: Infinity,
        language: {
            noResults() {
                return 'Ничего не найдено';
            }
        }
    };

    if (!$selects.length) {
        return;
    }

    $selects.each((index, item) => initSelect($(item), options));
}
